import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const MediaApiService = {
  URL: "",

  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },

  API_IMAGE_UPLOAD_WITHOUT_SAVING: "api/upload/image-upload-without-saving-to-db",
  API_MEDIA: "api/upload/media-upload",

  /**
   *
   * @param {*} object that contains a body
   * @returns
   */
  uploadImageWithoutSavingToDB(image) {
    try {
      // FORMDATA
      const formData = new FormData();
      formData.append("image", image);

      // HEADERS
      const headers = {
        "Content-Type": "multipart/form-data"
      };

      return Vue.axios.post(this.URL + this.API_IMAGE_UPLOAD_WITHOUT_SAVING, formData, {
        headers
      });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MediaApiService ${error}`);
    }
  },
  mediaUpload(files = [], params) {
    try {
      const imageFormData = new FormData();

      files
        ?.filter(exists => exists)
        .map(item => {
          imageFormData.append("files", item);
        });

      return Vue.axios.post(this.URL + this.API_MEDIA, imageFormData, { params });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MediaApiService ${error}`);
    }
  }
};

export default MediaApiService;
