import { render, staticRenderFns } from "./image-upload.vue?vue&type=template&id=df947c9e&scoped=true"
import script from "./image-upload.component.js?vue&type=script&lang=js&external"
export * from "./image-upload.component.js?vue&type=script&lang=js&external"
import style0 from "./image-upload.vue?vue&type=style&index=0&id=df947c9e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df947c9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VFileInput,VIcon})
