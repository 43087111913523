export default {
  props: {
    value: null
  },
  emits: ["update:value"],
  name: "image-upload",
  data() {
    return { uploadedImage: null, showPreview: false };
  },
  mounted() {
    if (this.value) {
      this.updatePreview(this.value);
      this.showPreview = true;
    }
  },
  methods: {
    updatePhoto() {
      const fileInput = this.$refs.fileInput.$el.querySelector("input[type=file]");
      const image = fileInput.files[0];

      if (!this.uploadedImage) {
        if (!image) return;

        const file = fileInput.files[0];
        this.uploadedImage = file;

        this.updateModel();
        this.loadPreview(this.uploadedImage);
        this.showPreview = true;
      } else {
        this.onRemoveImage();
      }
    },
    onRemoveImage() {
      // this.$refs.fileInput.$el.querySelector("input[type=file]").value = "";
      this.uploadedImage = null;
      this.showPreview = false;
      this.$refs.imageContainer.style.backgroundImage = "none";
      this.updateModel();
    },
    updateModel() {
      this.$emit("change", this.uploadedImage);
    },
    loadPreview(file) {
      const reader = new FileReader();
      reader.onload = () => {
        // SET BG IMAGE
        const previewImage = reader.result;
        this.updatePreview(previewImage);
      };

      reader.readAsDataURL(file);
    },
    updatePreview(url) {
      if (!this.$refs.imageContainer) return;
      this.$refs.imageContainer.style.backgroundImage = `url(${url})`;
    }
  }
};
