import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ImageUpload from "@/view/content/widgets/image-upload/image-upload";
import { VALIDATORS } from "@/common/validators";
import MediaApiService from "@/core/services/api.service.media";
import { DYNAMIC_PROPERTY } from "@/common/dynamic-property/dynamic-property";
import { CAMPAIGN_WELCOME_SCREEN } from "@/common/dynamic-property/group-settings/campaign-welcome-screen";
import SettingsApiService from "@/core/services/api.service.settings";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import SchedulerApiService from "@/core/services/api.service.scheduler";

const NAMES = CAMPAIGN_WELCOME_SCREEN.PROPERTY_NAMES;

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "welcome-screen-cities",
  components: {
    ImageUpload
  },
  data() {
    return {
      valid: false,
      loading: false,
      formData: null,
      isDefault: false,
      isEdit: false,
      properties: NAMES,
      loadImages: false,
      countries: [],
      selectedCountry: null,
      selectedCity: [],
      form: {
        countryId: null,
        cityId: null,
        validFrom: null,
        validUntil: null,
        settingType: null,
        [NAMES.HEADER_IMAGE]: {},
        [NAMES.MAIN_TITLE]: {},
        [NAMES.TOP_PARAGRAPH]: {},
        [NAMES.BOTTOM_PARAGRAPH]: {},
        [NAMES.BUTTON_TEXT]: {},
        [NAMES.BELOW_BUTTON_TEXT]: {}
      },
      requiredRule: VALIDATORS.required,
      valueTypes: DYNAMIC_PROPERTY.VALUE_TYPES,
      propertyNames: Object.values(NAMES),
      cities: [],
      citySearch: "",
      dateFormat: "yyyy-MM-DD HH:mm:ss"
    };
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.formData = this.$route.params?.edit || {};
    this.isEdit = !!this.$route.params?.isEdit;
    this.isDefault =
      this.formData?.settingType === CAMPAIGN_WELCOME_SCREEN.SETTING_TYPE.DEFAULT_SETTING;

    await MediaApiService.init();
    await ApiService.init();
    await SchedulerApiService.init();
    this.getCities();
    this.getCountries();
    this.form = this.setForm(this.formData);
    console.log("form", this.form);
    // this.selectedCountry.id = this.form.countryId || null;
    this.loadImages = true;
  },
  methods: {
    setForm(data) {
      return {
        countryId: data?.countryId || null,
        cityId: data?.cityId || null,
        validFrom: this.formatUtcToLocalDate(data?.validFrom),
        validUntil: this.formatUtcToLocalDate(data?.validUntil),
        settingType: data?.settingType || null,
        [NAMES.HEADER_IMAGE]: this.createInnerForm(data, NAMES.HEADER_IMAGE),
        [NAMES.MAIN_TITLE]: this.createInnerForm(data, NAMES.MAIN_TITLE),
        [NAMES.TOP_PARAGRAPH]: this.createInnerForm(data, NAMES.TOP_PARAGRAPH),
        [NAMES.BOTTOM_PARAGRAPH]: this.createInnerForm(data, NAMES.BOTTOM_PARAGRAPH),
        [NAMES.BUTTON_TEXT]: this.createInnerForm(data, NAMES.BUTTON_TEXT),
        [NAMES.BELOW_BUTTON_TEXT]: this.createInnerForm(data, NAMES.BELOW_BUTTON_TEXT)
      };
    },
    disablePastDates(date) {
      // Disable dates before today
      const today = new Date();
      return date >= today;
    },
    createInnerForm(data, propertyName) {
      const formattedData = data[propertyName];
      const valueType = CAMPAIGN_WELCOME_SCREEN.VALUE_MAP[propertyName];

      return {
        id: formattedData?.id || null,
        propertyGroup: formattedData?.propertyGroup || CAMPAIGN_WELCOME_SCREEN.PROPERTY_GROUP,
        propertyName: formattedData?.propertyName || propertyName,
        valueType: formattedData?.valueType || valueType,
        propertyValue: formattedData?.propertyValue || null
      };
    },
    formatUtcToLocalDate(date) {
      return date
        ? moment
            .utc(date)
            .local()
            .format(this.dateFormat)
        : null;
    },
    formatToUtc(date) {
      return date
        ? moment(date)
            .utc()
            .format()
        : null;
    },
    onImageUpdate(event, formControl) {
      this.onChange(formControl);
      const file = event;

      if (!file) {
        formControl.propertyValue = null;
        return;
      }

      MediaApiService.uploadImageWithoutSavingToDB(file).then(response => {
        formControl.propertyValue = response.data;
      });
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    close() {
      this.$router.go(-1);
    },
    getCities() {
      // if (!this.selectedCountry) return;
      /*   ApiService.get("api/cities").then(response => {
        this.cities = response.data;
      });*/

      return SchedulerApiService.getCities({
        countryId: this.selectedCountry?.id ? this.selectedCountry?.id : this.selectedCountry
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChange(item) {
      item["isDirty"] = true;
    },
    getItemsAsArray(data) {
      return [
        data[NAMES.HEADER_IMAGE],
        data[NAMES.MAIN_TITLE],
        data[NAMES.TOP_PARAGRAPH],
        data[NAMES.BOTTOM_PARAGRAPH],
        data[NAMES.BUTTON_TEXT],
        data[NAMES.BELOW_BUTTON_TEXT]
      ];
    },
    removeAllTypes(data) {
      delete data[NAMES.HEADER_IMAGE];
      delete data[NAMES.MAIN_TITLE];
      delete data[NAMES.TOP_PARAGRAPH];
      delete data[NAMES.BOTTOM_PARAGRAPH];
      delete data[NAMES.BUTTON_TEXT];
      delete data[NAMES.BELOW_BUTTON_TEXT];
    },
    onCommonDataChange() {
      const list = this.getItemsAsArray(this.form);

      //mark as dirty if value changed
      list.forEach(item => {
        if (!item.id) return;
        item.isDirty = true;
      });
    },
    onCountryChange() {
      this.getCities();
    },
    handleSelectAllCities(event) {
      if (event) {
        const ids = this.cities.map(item => item.id) || [];
        this.selectedCity = ids;
        this.allCitiesSelected = true;
        this.getOobleeCategories();
      } else {
        this.selectedCity = [];
        this.allCitiesSelected = false;
      }
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
          this.countriesFilter = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    async save() {
      if (!this.isEdit) {
        this.form.countryId = this.selectedCountry.id;
      }
      const postModel = { ...this.form };
      console.log('postModel => ', postModel);
      const group = CAMPAIGN_WELCOME_SCREEN.PROPERTY_GROUP;

      // settings need to be formatted into an array
      const formatSettings = this.getItemsAsArray(postModel);
      const filterChanged = formatSettings.filter(item => item?.isDirty);
      const toBeUpdated = filterChanged.filter(item => item?.propertyValue);
      const toBeDeleted = filterChanged
        .filter(item => !item?.propertyValue)
        .map(item => item?.id)
        .join(",");

      postModel["settings"] = toBeUpdated;
      this.removeAllTypes(postModel);

      postModel["validUntil"] = this.formatToUtc(postModel?.validUntil);
      postModel["validFrom"] = this.formatToUtc(postModel?.validFrom);

      try {
        if (toBeDeleted?.length) {
          const group = CAMPAIGN_WELCOME_SCREEN.PROPERTY_GROUP;
          this.loading = true;
          await SettingsApiService.deleteSetting(group, toBeDeleted).finally(() => {
            this.loading = false;
          });
        }

        if (toBeUpdated?.length) {
          let request = null;

          if (this.isEdit) {
            request = SettingsApiService.updateSettingGroup(group, postModel);
          } else {
            request = SettingsApiService.createSettingGroup(group, postModel);
          }

          this.loading = true;
          await request.finally(() => {
            this.loading = false;
          });
        }

        this.close();
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.welcomeScreen"),
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast("Error: " + error, {
          title: this.$i18n.t("menu.messages"),
          solid: true,
          variant: "error",
          autoHideDelay: 2000
        });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.welcomeScreen");
    }
  }
};
